import React from "react"
import Iframe from 'react-iframe'

const privacy = props => {
  return (
    <>
      <Iframe url="https://api.us.xwatch.io/xfive/content/privacyPolicy?locale=en"
        width="100%"
        height="5000px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative" />
    </>
  )
}

export default privacy
